<h2>Key Performance Indicators</h2>
<div class="filter-panel">
  <dx-select-box
    width="180px"
    height="30px"
    [dataSource]="periods"
    displayExpr="name"
    valueExpr="value"
    [(value)]="period"
    (onValueChanged)="rebuild()"></dx-select-box>

  <dx-select-box
    width="180px"
    height="30px"
    [dataSource]="groups"
    displayExpr="name"
    valueExpr="value"
    [(value)]="group"
    (onValueChanged)="rebuild()"></dx-select-box>
</div>
<dx-data-grid
  class="content"
  [dataSource]="dso"
  [twoWayBindingEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxi-column caption="Key" dataField="_id" dataType="string" sortOrder="desc"></dxi-column>
  <dxi-column caption="Vehicles on Duty" dataField="vehiclesCount" dataType="number"></dxi-column>
  <dxi-column caption="Employees on Duty" dataField="employeesCount" dataType="number"></dxi-column>
  <dxi-column caption="Actual Trips" dataField="actualTripsCount" dataType="number"></dxi-column>
  <dxi-column caption="Actual Trips per Vehicle" dataField="actualTripsCountPerVehicle" dataType="number"></dxi-column>
  <dxi-column caption="Trips on manifest" dataField="tripsOnManifestCount" dataType="number"></dxi-column>
  <dxi-column
    caption="Trips on manifest per Vehicle"
    dataField="tripsOnManifestCountPerVehicle"
    dataType="number"></dxi-column>
  <dxi-column caption="Canceled Trips" dataField="canceledTripsCount" dataType="number"></dxi-column>
  <dxi-column caption="Unique Clients Transported" dataField="clientsCount" dataType="number"></dxi-column>
  <dxi-column
    caption="Unique Subscription Clients Transported"
    dataField="subscribedClientsCount"
    dataType="number"></dxi-column>
  <dxi-column caption="Load Time Hours" dataField="loadDuration" dataType="number"></dxi-column>
  <dxi-column caption="Load Time Hours per Vehicle" dataField="loadDurationPerVehicle" dataType="number"></dxi-column>
  <dxi-column caption="Free Time Hours" dataField="freeTimeDuration" dataType="number"></dxi-column>
  <dxi-column
    caption="Free Time Hours per Vehicle"
    dataField="freeTimeDurationPerVehicle"
    dataType="number"></dxi-column>
  <dxi-column caption="Engine On Hours" dataField="onDutyEngineOnDuration" dataType="number"></dxi-column>
  <dxi-column
    caption="Engine On Hours per Vehicle"
    dataField="onDutyEngineOnDurationPerVehicle"
    dataType="number"></dxi-column>
  <dxi-column caption="Engine Off Hours" dataField="onDutyEngineOffDuration" dataType="number"></dxi-column>
  <dxi-column
    caption="Engine Off Hours per Vehicle"
    dataField="onDutyEngineOffDurationPerVehicle"
    dataType="number"></dxi-column>
  <dxi-column caption="Idle Hours" dataField="onDutyIdleDuration" dataType="number"></dxi-column>
  <dxi-column caption="Idle Hours per Vehicle" dataField="onDutyIdleDurationPerVehicle" dataType="number"></dxi-column>
  <dxi-column caption="Miles Driven" dataField="distance" dataType="number"></dxi-column>
  <dxi-column caption="Miles per Vehicle" dataField="distancePerVehicle" dataType="number"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'YesNoCellTpl'">
    {{ (cellInfo.value && 'YES') || '' }}
  </ng-container>
</dx-data-grid>

